import React from 'react'
import { string } from 'prop-types'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

const ButtonBig = styled(({ text, url, textColor, backgroundColor }) => (
  <Link
    to={url}
    className={
      'btn ' +
      (textColor ? `${textColor} ` : '') +
      (backgroundColor ? `${backgroundColor} ` : '')
    }
  >
    {text}
  </Link>
))`
  font-size: 20px;
  padding: 14px 20px;
`

ButtonBig.propTypes = {
  text: string.isRequired,
  url: string.isRequired,
  textColor: string,
  backgroundColor: string
}

ButtonBig.defaultProps = {
  text: "Let's Get Started",
  url: '/contact',
  textColor: 'text-dark',
  backgroundColor: 'bg-warning'
}

export default ButtonBig
