import { useStaticQuery, graphql } from "gatsby"

export const notFoundContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allNotFoundJson } = useStaticQuery(
    graphql`
      query queryNotFoundContent {
        allNotFoundJson {
          edges {
            node {
              bigButton {
                text
                url
                textColor
                backgroundColor
              }
            }
          }
        }
      }
    `
  )

  return [allNotFoundJson.edges]
}
