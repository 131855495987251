import React from 'react'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Layout from '@compositions/Layout'
import SEO from '@components/SEO'
import ButtonBig from '@components/ButtonBig'

import { notFoundContent } from '@hooks/use-not-found-data'

import NFD from '@images/NFD.svg'
import NFM from '@images/NFM.svg'

const NotFoundPage = styled(({ className }) => {
  const { bigButton } = notFoundContent()[0][0].node

  return (
    <Layout className={className}>
      <SEO title="404: Not found" />
      <Container>
        <Row>
          <Col className="my-3">
            <section className="position-relative">
              <NFM className="d-block d-md-none" />
              <NFD className="d-none d-md-block" />
              <h3 className="text-white text-center not-found">
                Page not found
              </h3>
              <div className="go-back">
                <ButtonBig
                  text={bigButton.text}
                  url={bigButton.url}
                  textColor={bigButton.textColor}
                  backgroundColor={bigButton.backgroundColor}
                />
              </div>
            </section>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
})`
  .not-found,
  .go-back {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }

  .not-found {
    bottom: 24%;

    @media (min-width: 768px) {
      bottom: 26%;
    }

    @media (min-width: 992px) {
      bottom: 32%;
    }
  }

  .go-back {
    bottom: 16%;

    @media (min-width: 768px) {
      bottom: 16%;
    }

    @media (min-width: 992px) {
      bottom: 24%;
    }
  }
`

export default NotFoundPage
